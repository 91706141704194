import React from 'react';
import _ from 'lodash';
import qs from 'qs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet, Text, View, Image } from 'react-native';
import { NavigationPage, NavigationBar, ScreenLoading, Progress, NetImage, Dialog, Touchable } from '../../components';
import { Button, Theme } from 'ui-m/rn';
import { handleRequestError, mcRequest, request, navigate } from '../../utils';
import { callNativeAsyncFunc, NativeFuncs } from '../../utils/mcAppJsSDK';
import resources from '../../config/resources';
import * as daService from '../../services/dataAnalyses';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return this.props.i18n.map["stream.importGoods.title"];
    },
    primary: true,
    renderNavigationLeftView() {
      return <NavigationBar.Button icon="close" onPress={() => {
        this.isClosed = true;
        callNativeAsyncFunc(NativeFuncs.SYSTEM.CLOSE);
      }} />;
    },
  };

  static propTypes = {
    ...NavigationPage.propTypes,
    // 路由入参声明
    route: PropTypes.shape({
      params: PropTypes.exact({
        catchedIntention: PropTypes.object,
      }),
    }),
  }

  get catchedIntention() {
    return this.props.route.params?.catchedIntention;
  }

  get intentionParams() {
    return this.catchedIntention.intentionParams;
  }

  get tempGoodsId() {
    return this.intentionParams.tempGoodsId;
  }

  constructor(props) {
    super(props);

    this.state = {
      tempGoods: {
        companyName: this.intentionParams.companyName,
        itemRef: this.intentionParams.itemRef,
        coverImage: this.intentionParams.coverImage,
      },
      progress: 0,
      progressDetail: null,
      progressDetailI18nKey: "stream.importGoods.loadingProduct",
      status: null,
      statusDetail: null,

    };
  }

  importFailed = res => {
    const i18n = this.props.i18n;
    daService.create({ type: "importFromMS_single_prepare_failed" });

    this.setState({
      status: "failed",
      statusDetail: res.errorMessage || i18n.map["stream.importGoods.failed.detail.default"],
    });

    // handleRequestError执行回调总是失败,临时这么写
    if (res.status === 401 || res.errorCode === 6011) {
      Dialog.show({
        type: 'alert',
        title: i18n.map['cmn.tip_401'],
        rightBtnText: i18n.map['cmn.btn.ok'],
        onRightClick: () => this.closeWeb(),
      });
    }
  }

  componentDidMount() {
    daService.create({ type: "PV_importFromMS_single" });
    this.ensurePreference();
  }

  ensurePreference = async () => {
    if (this.props.preference.settings) {
      return this.props.preference.settings;
    }
    await this.props.dispatch({ type: "preference/getPreference" });
  }

  handleModifyPreference = async () => {
    ScreenLoading.show();
    await this.ensurePreference();
    ScreenLoading.hide();
    navigate("ImportProductPreference");
  }

  importGoods = async () => {

    // 获取 MS临时商品
    this.setState({ progress: 10, status: "uploading" });
    // 冗余确保下
    await this.ensurePreference();
    if (this.isClosed) { return; }
    const res1 = await this.getMSTempGoods();
    if (!res1.success) {
      return this.importFailed(res1);
    }
    const tempGoods = res1.data;
    this.setState({
      tempGoods: {
        ...this.state.tempGoods,
        ...tempGoods,
      },
      progress: 37,
      progressDetail: null,
      progressDetailI18nKey: "stream.importGoods.createMCTempGoods",
    });

    // 创建 MC临时商品
    if (this.isClosed) { return; }
    const res2 = await this.createMCTempGoods(tempGoods);
    if (!res2.success) {
      return this.importFailed(res2);
    }
    const mcTempGoodsCode = res2.data.code;
    this.setState({
      progress: 87,
      progressDetail: null,
      progressDetailI18nKey: "stream.importGoods.setMSTempGoods",
    });

    // 存储 MC临时商品code
    if (this.isClosed) { return; }
    const res3 = await this.saveMCTempGoodsCode(mcTempGoodsCode);
    if (!res3.success) {
      return this.importFailed(res3);
    }
    this.setState({
      progress: 100,
      progressDetail: null,
      progressDetailI18nKey: null,
    });

    if (this.isClosed) { return; }
    // 完成
    daService.create({
      type: "importFromMS_single_prepare_success",
      remark: mcTempGoodsCode,
    });

    this.callCreateProductNativeAction(mcTempGoodsCode);

    // android黑端则同时关闭页面
    if (/Linux|Android/i.test(navigator.platform)) {
      this.closeWeb();
    } else {
      this.setState({ status: "finish" });
    }
  }

  getMSTempGoods = () => request(
    `/tempGoods/${this.tempGoodsId}`,
    { data: { mcKey: this.mcKey } }
  )

  createMCTempGoods = tempGoods => {
    const { importUnitAndPackageContent } = this.props.preference.settings || {};

    return mcRequest(
      `/goods/save_temp_goods?${qs.stringify({ key: this.mcKey, lang: this.lang })}`,
      {
        method: "POST",
        data: {
          item_ref: tempGoods.itemRef,
          name: tempGoods.name,
          price_in: tempGoods.price, // 进货价
          weight: tempGoods.weight * 1000, // mc新建商品的重量单位为g,绿端为kg
          skus: (tempGoods.skuList || []).map(i => ({
            imgs: i.images,
            color: i.colorName,
            size: i.sizeName,
            goods_sn: i.goodsSn,
          })),
          remark_material: tempGoods.remarkMaterial,
          unit_number: importUnitAndPackageContent ? tempGoods.unitNumber : undefined,
          remark_package: importUnitAndPackageContent ? tempGoods.remarkPackage : undefined,
        },
      }
    );
  }

  saveMCTempGoodsCode = mcTempGoodsCode => request(
    `/tempGoods/${this.tempGoodsId}/mcTempGoodsCode?${qs.stringify({ mcKey: this.mcKey })}`,
    { method: "PATCH", data: { mcTempGoodsCode } }
  )

  callCreateProductNativeAction = mcTempGoodsCode => (
    callNativeAsyncFunc(NativeFuncs.NAVIGATION.GENERAL, {
      "route": "/create_module",
      "params": { "key": "product_air_into", "present": "1", "id": mcTempGoodsCode },
    })
  )

  closeWeb = () => callNativeAsyncFunc(NativeFuncs.SYSTEM.CLOSE)

  retry = () => {
    this.setState({
      status: null,
      statusDetail: null,
      progress: 0,
      progressDetail: null,
      progressDetailI18nKey: "stream.importGoods.loadingProduct",
    }, this.importGoods);
  }

  renderPage() {
    const i18n = this.props.i18n;
    const { tempGoods, progress, progressDetail, progressDetailI18nKey, status, statusDetail } = this.state;

    return (
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer} >
        {
          // 设置项
          !status && (
            <Touchable
              style={styles.preference}
              onPress={this.handleModifyPreference}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <Image style={styles.preferenceIcon} source={resources.importPreference} />
              <Text style={styles.preferenceText}>{i18n.map.preference}</Text>
            </Touchable>
          )
        }
        {
          !status && (
            <View style={styles.verticalWrap}>
              <Progress.Border
                style={styles.coverImageWrap}
                progressWidth={3}
                progressColor="#0076FF"
                progress={progress}
              >
                <NetImage
                  style={styles.coverImage}
                  url={NetImage.crop(tempGoods.coverImage, 300)}
                />
              </Progress.Border>
              <View style={styles.itemRefWrap}>
                <Text style={styles.itemRef}>{tempGoods.companyName}: </Text>
                <Text style={styles.itemRef}>{tempGoods.itemRef}</Text>
              </View>
              <Button
                link
                primary
                style={{ width: 250, marginTop: 61 }}
                textStyle={{ fontWeight: "bold" }}
                onPress={this.importGoods}
              >
                {i18n.map["cmn.continue"]}
              </Button>
            </View>
          )
        }
        {
          // 进行中
          status === "uploading" && (
            <View style={styles.verticalWrap}>
              <Progress.Border
                style={styles.coverImageWrap}
                progressWidth={3}
                progressColor="#0076FF"
                progress={progress}
              >
                <NetImage
                  style={styles.coverImage}
                  url={NetImage.crop(tempGoods.coverImage, 300)}
                />
              </Progress.Border>
              <View style={styles.itemRefWrap}>
                <Text style={styles.itemRef}>{tempGoods.companyName}: </Text>
                <Text style={styles.itemRef}>{tempGoods.itemRef}</Text>
              </View>
              <Text style={styles.progressDetail}>{progressDetail || i18n.map[progressDetailI18nKey]}</Text>
            </View>
          )
        }
        {
          // 失败
          status === "failed" && (
            <View style={[styles.verticalWrap, styles.failedWrap]} >
              <Image source={{ uri: resources.importFailed, width: 100, height: 100 }} />
              <Text style={styles.failedTitle}>{i18n.map["stream.importGoods.failed"]}</Text>
              <View style={[styles.itemRefWrap, { marginTop: 10 }]}>
                <Text style={styles.failedItemRef}>{tempGoods.companyName}: </Text>
                <Text style={styles.failedItemRef}>{tempGoods.itemRef}</Text>
              </View>
              <Text style={styles.failedDetail}>{statusDetail || " "}</Text>
              <Button
                primary
                style={{ width: 345 }}
                textStyle={{ fontWeight: "bold" }}
                onPress={this.retry}
              >
                {i18n.map["cmn.btn.retry"]}
              </Button>
              <Button
                link
                style={{ marginTop: 4 }}
                textStyle={{ fontWeight: "bold" }}
                onPress={this.closeWeb}
              >
                {i18n.map["cmn.btn.close"]}
              </Button>
            </View>
          )
        }
        {
          // 结束
          status === "finish" && (
            <View style={styles.finishWrap}>
              <NetImage
                style={styles.finishImage}
                url={NetImage.crop(tempGoods.coverImage, 200)}
              />
              <View style={[styles.itemRefWrap, { marginTop: 15, marginBottom: 130 }]}>
                <Text style={styles.finishItemRef}>{tempGoods.companyName}: </Text>
                <Text style={styles.finishItemRef}>{tempGoods.itemRef}</Text>
              </View>
              <Button
                style={{ width: 345 }}
                textStyle={{ fontWeight: "bold" }}
                onPress={this.closeWeb}
              >
                {i18n.map["cmn.btn.close"]}
              </Button>
            </View>
          )
        }
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    minHeight: "100%",
    alignItems: "stretch",
    justifyContent: "center",
  },
  preference: {
    flexDirection: "row",
    position: "absolute",
    top: 15,
    right: 17,
  },
  preferenceIcon: {
    width: 20,
    height: 20,
  },
  preferenceText: {
    fontSize: 14,
    lineHeight: 20,
    color: "#767676",
    marginLeft: 8,
  },
  verticalWrap: {
    alignItems: "center",
    paddingBottom: 63,
  },
  coverImageWrap: {
    width: 161.5,
    height: 161.5,
    backgroundColor: "#E8E8E8",
  },
  coverImage: {
    position: "absolute",
    top: 1.5,
    left: 1.5,
    width: 158.5,
    height: 158.5,
  },
  itemRefWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: 310,
    marginTop: 8,
    wordBreak: "break-word",
  },
  itemRef: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
  },
  progressDetail: {
    color: "#999",
    fontSize: 14,
    lineHeight: 20,
    marginTop: 40,
  },

  // failed
  failedWrap: {
    paddingBottom: 80,
  },
  failedTitle: {
    maxWidth: 310,
    textAlign: "center",
    fontSize: 20,
    lineHeight: 28,
    color: "#212B36",
    fontWeight: "bold",
    marginTop: 50,
  },
  failedItemRef: {
    textAlign: "center",
    fontSize: 16,
    lineHeight: 18,
    color: "#999999",
  },
  failedDetail: {
    maxWidth: 310,
    textAlign: "center",
    fontSize: 16,
    lineHeight: 18,
    color: "#666666",
    marginTop: 10,
    marginBottom: 70,
  },

  // finish
  finishWrap: {
    flex: 1,
    alignItems: "center",
  },
  finishImage: {
    width: 100,
    height: 100,
    borderRadius: 4,
    overflow: "hidden",
    marginTop: 130,
    backgroundColor: "#E8E8E8",
  },
  finishItemRef: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
  },

});

export default connect((store) => _.pick(store, [
  'i18n',
  'app',
  'preference',
]))(Page);
