import React from 'react';
import _ from 'lodash';
import { BackHandler, Text, Image, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
// import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Theme } from 'ui-m/rn';
import { NavigationBar, FeedbackHelper } from './components';
import { navigationRef, _setNavigationReady, _setLastRootState, _setLastCurrentRoute } from './utils/navigation';
import { createAction } from './utils';
import { connect } from 'react-redux';
import { Toast } from './components';
import resources from './config/resources';
import * as daService from './services/dataAnalyses';

import SplashScreen from './containers/SplashScreen';
import FrameworkStates from './containers/DevTools/FrameworkStates';
import DevTools from './containers/DevTools';
import IntentionLauncher from './containers/IntentionLauncher/index';
import ComponentPlayground from "./containers/ComponentPlayground/index";

// 上下游
import SupplyChain from './containers/SupplyChain';
import DashboardViewer from './containers/SupplyChain/DashboardViewer';
import SelectSupplier from './containers/SupplyChain/SelectSupplier';

import DashboardLogin from './containers/DashboardLogin';
import DashboardReports from './containers/DashboardReports';

import ImportProductFromMS from './containers/ImportProductFromMS';
import ImportProductPreference from "./containers/ImportProductFromMS/Preference";

import SalesChannelList from "./containers/SalesChannel/List";
import SalesChannelEfashion from "./containers/SalesChannel/Efashion";

import { useSelector } from 'react-redux';
import { testId } from './utils/uitest';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const parseScreenProps = function(props = {}){
  let {
    // 以模态框方式从下往上滑动入场，并且页头区域带缩进阴影
    modalPresentation = false,
    // 是否展示在模态框 Stack 中（不一定以模态框动画入场）
    isInModalStack,
    options = {},
    component: NavigationPage = ()=>null,
    ...rest
  } = props;
  const name = props.name;
  // 以模态框方式入场的，一定在模态框 Stack 里面
  if (modalPresentation) {
    isInModalStack = true;
  }
  // 追加默认 props
  options = {
    headerShown: false,
    cardOverlayEnabled: modalPresentation,
    // TODO 看看 iOS 有没有问题
    // ...modalPresentation ? TransitionPresets.ModalPresentationIOS : TransitionPresets.SlideFromRightIOS,
    ...options,
  };
  const children = (props)=>{
    return (
      <NavigationPage
        {...props}
        {...testId(name)}
        isInModalStack={isInModalStack}
        modalPresentation={modalPresentation}
        isGestureBackEnabled={options?.gestureEnabled}
        pageContainerStyle={isInModalStack ? {
          marginTop: Theme.navBarContentHeight + NavigationBar.MODAL_BAR_TOP_SPACE,
        } : null} />
    );
  };
  return {
    options,
    children,
    ...rest,
  };
};

function _createNavigator(propsOfScreenList = [
  /**
   * {
   *   name: 'Home',
   *   component: HomeScreen,
   *   options: {}
   * }
  */
], options = {}){
  const {
    type = 'stack', // 'stack', 'modal'
  } = options;
  const finalPropsOfScreenList = _.uniqBy(propsOfScreenList, _=>_.name);
  if (propsOfScreenList.length !== finalPropsOfScreenList.length) {
    console.warn(`router.js: ${type} 中有重复的 Screen name, (${propsOfScreenList.map(_=>_.name).join(', ')})`);
  }
  function StackNavigator(props = {}) {
    return (
      <Stack.Navigator modal={type === 'modal'}>
        {finalPropsOfScreenList.map((screenProps, index)=>{
          const modalProps = {};
          if (type === 'stack') {
            // 父导航是模态框式打开，那么这个 Stack 的第一个页面也是（用于左上角后退按钮的动态识别，是叉叉还是后退）
            if (index === 0) {
              modalProps.modalPresentation = props.modalPresentation;
            }
            // 父导航是模态框里的页面，那么这个页面也是（用于页头缩进样式的识别）
            else {
              modalProps.isInModalStack = props.isInModalStack;
            }
          }
          else if (type === 'modal') {
            // 如果是第一个页面，不以模态框形式打开
            if (index === 0) {
              // 如果如果父导航是模态页面，加个间距
              modalProps.isInModalStack = props.isInModalStack;
            }
            // 不是第一个页面，用模态框形式打开
            else {
              modalProps.modalPresentation = true;
            }
          }
          return (
            <Stack.Screen
              key={screenProps.name}
              {...parseScreenProps({
                ...modalProps,
                ...screenProps,
              })}
            />
          );
        })}
      </Stack.Navigator>
    );
  }
  StackNavigator.propsOfScreenList = finalPropsOfScreenList;
  return StackNavigator;
}

function createStack(propsOfScreenList){
  return _createNavigator(propsOfScreenList, { type: 'stack' });
}

function createModal(propsOfScreenList){
  return _createNavigator(propsOfScreenList, { type: 'modal' });
}

const LaunchScreen = createStack([
  // Mobile & Desktop
  { name: 'SplashScreen', component: SplashScreen },
]);

const SupplyChainStack = createStack([
  // Mobile
  { name: 'SupplyChain', component: SupplyChain },
  { name: 'SelectSupplier', component: SelectSupplier },
  { name: 'DashboardViewer', component: DashboardViewer },
]);

const ImportProductStack = createStack([
  { name: 'ImportProductFromMS', component: ImportProductFromMS },
  { name: "ImportProductPreference", component: ImportProductPreference },
]);

const SalesChannelStack = createStack([
  { name: 'SalesChannelList', component: SalesChannelList },
  { name: 'SalesChannelEfashion', component: SalesChannelEfashion },
]);

const EntriesStack = createStack([
  /* 以下为 Modal 视图组（组内横向 Stack 切换） */
  // Mobile & Desktop
  { name: 'SplashScreen', component: SplashScreen },
  { name: 'DashboardLogin', component: DashboardLogin },
  { name: 'DashboardReports', component: DashboardReports },

  // Mobile
  { name: 'SupplyChainStack', component: SupplyChainStack },
  { name: 'ImportProductStack', component: ImportProductStack },
  { name: "SalesChannelStack", component: SalesChannelStack },

  // Desktop

  // Dev
  { name: 'FrameworkStates', component: FrameworkStates },
  { name: 'DevTools', component: DevTools },
  { name: "IntentionLauncher", component: IntentionLauncher },
  { name: "ComponentPlayground", component: ComponentPlayground },
]);

class Router extends React.Component {

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.backHandle);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.backHandle);
  }

  backHandle = () => {
    // // // 在根页面时按后退，提示再按一次就离开 APP，然后离开
    // if (this._tippedOneMoreBackWillLeave) {
    //   return false;
    // }
    // else {
    //   Toast(this.props.i18n.map.back_again_to_leave_app);
    //   this._tippedOneMoreBackWillLeave = true;
    //   clearTimeout(this._oneMoreBackWillLeaveTmo);
    //   this._oneMoreBackWillLeaveTmo = setTimeout(()=>{
    //     this._tippedOneMoreBackWillLeave = false;
    //   }, 2000);
    //   return true;
    // }
  }

  render() {
    const { app, auth } = this.props;
    // const isInModalStack = true;
    let timer =  0;
    return (
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          _setNavigationReady();
        }}
        onStateChange={async () => {
          // const previousRouteName = routeNameRef.current;
          const currentRoute = navigationRef?.current?.getCurrentRoute() || {};
          const rootState = navigationRef?.current?.getRootState() || {};
          // 存储供异常监控使用
          _setLastRootState(rootState);
          _setLastCurrentRoute(currentRoute);
          if (currentRoute.name) {
            const params = {};
            // if (['ProductProfile', 'ProductForm'].includes(currentRoute.name)) {
            //   params.productId = currentRoute.params?.id;
            // }
            // else if (['OrderProfile'].includes(currentRoute.name)) {
            //   params.orderId = currentRoute.params?.id;
            // }
            // else if (['CustomerProfile', 'CustomerForm'].includes(currentRoute.name)) {
            //   params.remark = currentRoute.params?.id;
            // }
            let now = Date.now();
            if (now - timer > 50){
              daService.logPV({ screenName: currentRoute.name, ...params});
            }
            timer = now;
            // // 登录页不显示悬浮意见反馈入口
            // FeedbackHelper.handleNavigationChange(currentRoute);
          }
        }}
      >
        {!app.isReady ? (
          <LaunchScreen/>
        ) : (
          <EntriesStack/>
        )}
      </NavigationContainer>
    );
  }
}

export default connect(({ i18n, app, auth }) => ({
  i18n,
  app,
  auth,
}))(Router);
