import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet, View } from 'react-native';
import { NavigationPage, LabelBlock, NumberInput, NavigationBar } from '../../components';
import { callNativeAsyncFunc, NativeFuncs } from '../../utils/mcAppJsSDK';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return "Component Playground";
    },
    primary: true,
    renderNavigationLeftView() {
      return <NavigationBar.Button icon="close" onPress={() => {
        this.isClosed = true;
        callNativeAsyncFunc(NativeFuncs.SYSTEM.CLOSE);
      }} />;
    },
  };

  renderPage() {

    return (
      <ScrollView style={styles.container}>
        <LabelBlock text={`NumberInput (num: ${this.state.num})`} type="desc" />
        <View style={styles.inputWrap}>
          <NumberInput
            label="num"
            value={this.state.num}
            valid={num => num >= 0}
            onComplete={num => this.setState({ num })}
          />
        </View>
        <LabelBlock text={`NumberInput.Percent (percent: ${this.state.percent})`} type="desc" />
        <View style={styles.inputWrap}>
          <NumberInput.Percent
            label="percent"
            value={this.state.percent}
            valid={percent => percent >= 0}
            onComplete={percent => this.setState({ percent })}
          />
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inputWrap: {
    paddingHorizontal: 20,
  },
});

export default connect((store) => _.pick(store, [
  'i18n',
]))(Page);
