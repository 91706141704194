import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet } from 'react-native';
import { Dialog, LabelBlock, NavigationPage } from '../../components';
import { openMC } from '../../utils';
import { CellGroup, Cell } from "ui-m/rn";

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return "Intention Launcher";
    },
    primary: true,
  };

  renderPage() {

    return (
      <ScrollView style={styles.container}>
        <CellGroup>
          <Cell
            title="Supply Chain"
            onPress={() => {
              openMC({
                launchParam: `time=${Date.now()}&navigate=SupplyChainStack`,
              });
            }}
            showArrow
          />
          <Cell
            title="Third-party sales channel"
            onPress={() => {
              openMC({
                launchParam: `time=${Date.now()}&intention=${encodeURIComponent(JSON.stringify({ action: "thirdPartySalesChannelManagement" }))}`,
              });
            }}
            showArrow
          />
          <Cell
            title="Component Playground"
            onPress={() => {
              openMC({
                launchParam: `time=${Date.now()}&intention=${encodeURIComponent(JSON.stringify({ action: "componentPlayground" }))}`,
              });
            }}
            showArrow
          />
          <Cell
            title="Import Product"
            onPress={() => {
              let tempGoodsId;
              Dialog.show({
                type: "prompt",
                title: "tempGoodsId",
                leftBtnText: "cancel",
                rightBtnText: "confirm",
                inputStyle: {
                  backgroundColor: "rgba(51,51,51,0.06)",
                  height: 30,
                  borderRadius: 3.5,
                },
                hideInputBottomBorder: true,
                inputValue: tempGoodsId,
                handelInput: val => tempGoodsId = val,
                disabledRightBtnOnPromptEmpty: true,
                onRightClick: () => {
                  const intention = {
                    action: "importSingleProductFromMS",
                    tempGoodsId,
                    companyName: "COMPANYNAME",
                    itemRef: "ITEMREF",
                    coverImage: "",
                  };
                  openMC({ launchParam: `intention=${encodeURIComponent(JSON.stringify(intention))}` });
                },
              });
            }}
            showArrow
          />
        </CellGroup>
        <LabelBlock
          text={`info: ${JSON.stringify({
            lang: this.props.i18n.currentLang,
            region: this.props.i18n.currentRegion,
            useragent: navigator.userAgent,
            platform: navigator.platform,
          }, null, 2)}`}
          type="desc"
        />
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

});

export default connect((store) => _.pick(store, [
  'i18n',
  "app",
]))(Page);
